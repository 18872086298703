<template>
  <div class="error-404">
    404 Not Found
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
